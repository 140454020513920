<template>
  <b-modal
    v-model="visible"
    id="contract-payment-editor"
    :title="`${form.paymentId ? 'Edit' : 'Create'} payment`"
    size="xl"
    @hide="resetForm()"
    hide-footer
  >
    <b-form ref="paymentForm" class="row" @submit.prevent="savePayment()" @reset="resetForm()">
      <b-col cols="6">
        <b-form-group label="Payment Type">
          <b-form-select
            v-model="paymentType"
            :options="paymentTypeOptions"
            required
            @input="fillInFields()"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Payment Status">
          <b-form-select v-model="form.status" :options="statusOptions" required />
        </b-form-group>
      </b-col>

      <!-- Recurring payment fields -->
      <b-col v-if="paymentType == 'RECURRING'" cols="6">
        <b-form-group
          label="Frequency"
          :description="contract.contractLength + ' payments will be created'"
        >
          <b-form-select v-model="paymentFrequency" :options="paymentFrequencyOptions" required />
        </b-form-group>
      </b-col>
      <b-col v-if="paymentType == 'RECURRING'" cols="6">
        <b-form-group
          label="Start Date"
          :description="'Contract starts on ' + formatDate(contract.startDate)"
        >
          <b-input v-model="paymentStartDate" type="date" required />
        </b-form-group>
      </b-col>
      <b-col v-if="paymentType == 'RECURRING'" cols="6">
        <b-form-group label="Total Amount Due">
          <b-input v-model="totalAmountDue" type="number" required />
        </b-form-group>
      </b-col>
      <b-col v-if="paymentType == 'RECURRING'" cols="6">
        <b-form-group label="Monthly Amount Due (approx.)">
          <b-input-group>
            <b-form-input
              :value="calculatedAmountDue"
              :disabled="autoCalculate.monthlyAmountDue"
              type="number"
              step="0.01"
              @input="v => (form.amountDue = v)"
              :required="form.status == 'EXPECTED'"
            />
            <b-input-group-append>
              <b-button
                @click="autoCalculate.monthlyAmountDue = !autoCalculate.monthlyAmountDue"
                :variant="autoCalculate.monthlyAmountDue ? 'primary' : ''"
              >
                <font-awesome-icon :icon="['fas', 'calculator']" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- One-off payment fields -->
      <b-col v-if="paymentType == 'ONE-OFF'" cols="6">
        <b-form-group label="Due Date">
          <b-form-input v-model="form.dueDate" type="date" :required="form.status == 'EXPECTED'" />
        </b-form-group>
      </b-col>
      <b-col v-if="paymentType == 'ONE-OFF'" cols="6">
        <b-form-group label="Amount Due">
          <b-form-input
            v-model="form.amountDue"
            type="number"
            step="0.01"
            :required="form.status == 'EXPECTED'"
            @change="onAmountDueChange"
          /> </b-form-group
      ></b-col>
      <b-col v-if="paymentType == 'ONE-OFF'" cols="6">
        <b-form-group label="Received Date">
          <b-button
            size="sm"
            variant="light"
            class="border label-btn"
            @click="$set(form, 'receivedDate', form.dueDate)"
            >Paid on time</b-button
          >
          <b-form-input
            v-model="form.receivedDate"
            type="date"
            :required="form.status == 'RECEIVED'"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="paymentType == 'ONE-OFF'" cols="6">
        <b-form-group label="Amount Received">
          <b-button
            size="sm"
            variant="light"
            class="border label-btn"
            @click="$set(form, 'amountReceived', form.amountDue)"
            >Paid in full</b-button
          >
          <b-form-input
            v-model="form.amountReceived"
            type="number"
            step="0.01"
            :required="form.status == 'RECEIVED'"
            @change="onAmountReceivedChange"
          />
        </b-form-group>
      </b-col>

      <!-- Reference fields -->
      <b-col cols="6">
        <b-form-group label="Payment Reference">
          <b-form-textarea v-model="form.paymentFromReference" />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Note">
          <b-form-textarea v-model="form.reference" />
        </b-form-group>
      </b-col>

      <b-col>
        <h5>
          Commission
          <font-awesome-icon
            :icon="['fas', 'times-circle']"
            class="cursor text-primary add-commission-btn pointer"
            v-b-tooltip.hover="'Add new'"
            @click="addCommission"
          />
        </h5>
        <div v-if="!form.commissions || form.commissions.length === 0" class="d-flex">
          <span>No commissions yet. </span>
          <b-link href="#" variant="primary" class="underline ml-1" @click="autoPopulateCommissions"
            >Generate from contract</b-link
          >
        </div>
        <b-row
          v-else
          v-for="commission in form.commissions"
          :key="commission.id"
          class="bg-light border mx-0 pt-3 mb-3 rounded p-1 position-relative"
        >
          <div
            class="delete-commission-btn"
            v-b-tooltip.hover="'Delete'"
            @click="deleteCommission(commission.id)"
          >
            <div>
              <font-awesome-icon :icon="['fas', 'times-circle']" class="cursor text-secondary" />
            </div>
          </div>
          <b-col cols="4">
            <b-form-group label="User">
              <b-form-select
                :options="userOptions"
                v-model="commission.userId"
                value-field="userId"
                text-field="label"
                @change="v => updateCommissionPercentage(commission, v, commission._acPercentage)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Percentage">
              <b-input-group>
                <b-form-input
                  v-model="commission.percentage"
                  :disabled="commission._acPercentage"
                  type="number"
                  step=".01"
                  @change="
                    v =>
                      updateCommissionPercentage(
                        commission,
                        commission.userId,
                        commission._acPercentage
                      )
                  "
                />
                <b-input-group-append>
                  <b-button
                    @click="toggleAcPercentage(commission)"
                    :variant="commission._acPercentage ? 'primary' : ''"
                  >
                    <font-awesome-icon :icon="['fas', 'calculator']" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Expected Commission">
              <b-input-group>
                <b-form-input
                  v-model="commission.expectedCommission"
                  :disabled="commission._acExpectedCommission"
                  type="number"
                  step=".01"
                />
                <b-input-group-append>
                  <b-button
                    @click="toggleAcExpectedCommission(commission)"
                    :variant="commission._acExpectedCommission ? 'primary' : ''"
                  >
                    <font-awesome-icon :icon="['fas', 'calculator']" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Received Commission">
              <b-input-group>
                <b-form-input
                  v-model="commission.receivedCommission"
                  :disabled="commission._acReceivedCommission"
                  type="number"
                  step=".01"
                />
                <b-input-group-append>
                  <b-button
                    @click="toggleAcReceivedCommission(commission)"
                    :variant="commission._acReceivedCommission ? 'primary' : ''"
                  >
                    <font-awesome-icon :icon="['fas', 'calculator']" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="text-right">
        <b-button type="submit" variant="primary" class="mr-2" :disabled="busy">Save</b-button>
        <b-button v-if="!form.paymentId" type="reset" :disabled="busy">Reset</b-button>
      </b-col>
      <b-col cols="12" class="mt-2"
        ><b-alert :show="errorMessage != null" variant="danger">{{ errorMessage }}</b-alert></b-col
      >
    </b-form>
  </b-modal>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import moment from "moment";

export default {
  name: "ContractPaymentEditor",
  props: {
    contractId: {
      type: String,
      required: true
    },
    contract: {
      type: Object,
      required: false
    },
    users: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      busy: false,
      errorMessage: null,
      statusOptions: ["EXPECTED", "RECEIVED"],
      paymentType: "ONE-OFF",
      paymentTypeOptions: ["ONE-OFF", "RECURRING"],
      form: {
        status: "EXPECTED",
        amountDue: null,
        amountReceived: null,
        dueDate: null,
        receivedDate: null,
        reference: null,
        commissions: []
      },
      // Recurring payments
      paymentFrequency: "MONTHLY",
      paymentFrequencyOptions: ["MONTHLY"],
      paymentStartDate: null,
      totalAmountDue: null,
      autoCalculate: {
        monthlyAmountDue: true
      }
    };
  },
  watch: {
    visible(isVisible) {
      if (isVisible && !this.form.paymentId) {
        this.autoPopulateCommissions();
      }
    }
  },
  computed: {
    calculatedAmountDue() {
      if (this.contract && this.totalAmountDue && this.contract.contractLength) {
        return (
          Math.floor((this.totalAmountDue / this.contract.contractLength) * 100) / 100
        ).toFixed(2);
      }
      return null;
    },
    userOptions() {
      const missingUsers = []; // still display users in select even if they're not found
      const userIds = this.users.map(u => u.userId);
      this.form.commissions.forEach(c => {
        if (c.userId && !userIds.includes(c.userId)) {
          missingUsers.push({ userId: c.userId, label: c.userName });
        }
      });
      return [...this.users, ...missingUsers];
    }
  },
  methods: {
    dateToString: FormatHelper.formatDateStringWithTime,
    formatDate: d => FormatHelper.formatDateTimeCustom(d, "YYYY-MM-DD"),
    async savePayment() {
      this.busy = true;
      const client = await ApiHelper.http();
      return client
        .post(`${ApiHelper.endPoint()}payments/${this.contractId}`, {
          payments: this.convertForm()
        })
        .then(response => {
          this.showMessage("Payment(-s) saved successfully", "success");
          this.$emit("paymentSaved");
          this.visible = false;
          this.resetForm();
        })
        .catch(e => {
          this.showMessage("Couldn't save payment(-s)", "warning");
          this.errorMessage = "Couldn't save payment(-s): " + e.response.data.message;
          Console.error(e);
        })
        .finally(() => (this.busy = false));
    },

    createRecurringPayments() {
      let startDate = moment(this.paymentStartDate, "YYYY-MM-DD");
      let payments = [];
      let amountDue = parseFloat(this.calculatedAmountDue);
      let remainder = (this.totalAmountDue - amountDue * this.contract.contractLength) * 100;

      for (let i = 0; i < this.contract.contractLength; i++) {
        let p = Object.assign({}, this.form);
        let dueDate = moment(startDate)
          .add(i, "months")
          .format("YYYY-MM-DD");

        p.dueDate = this.dateToString(dueDate, false);
        p.amountDue = amountDue;

        if (remainder > 0) {
          p.amountDue += 0.01;
          remainder--;
        }
        if (this.form.status == "RECEIVED") {
          p.receivedDate = p.dueDate;
          p.amountReceived = p.amountDue;
        }
        payments[i] = p;
      }
      return payments;
    },

    autoPopulateCommissions() {
      if (this.contract) {
        this.form.commissions = [];
        if (this.contract.opsId) {
          const comm = this.addCommission();
          comm.userId = this.contract.opsId;
          this.updateCommissionPercentage(comm, this.contract.opsId, true);
        }
        if (this.contract.cpmId) {
          const comm = this.addCommission(1);
          comm.userId = this.contract.cpmId;
          this.updateCommissionPercentage(comm, this.contract.cpmId, true);
        }
        if (this.contract.apmId) {
          const comm = this.addCommission(2);
          comm.userId = this.contract.apmId;
          this.updateCommissionPercentage(comm, this.contract.apmId, true);
        }
        this.form.commissions.forEach(
          c => (c.percentage = this.getDefaultCommissionPercentage(c.userId))
        );
        this.form = { ...this.form }; // force update
      }
    },

    convertForm() {
      if (this.paymentType == "RECURRING") {
        return this.createRecurringPayments();
      }
      let formatted = Object.assign({}, this.form);
      if (formatted.dueDate != null) {
        formatted.dueDate = this.dateToString(formatted.dueDate, false);
      }
      if (formatted.receivedDate != null) {
        formatted.receivedDate = this.dateToString(formatted.receivedDate);
      }
      formatted.commissions = {};
      this.form.commissions.forEach(
        c => (formatted.commissions[c.userId] = { id: c.id || Date.now(), ...c })
      );
      return [formatted];
    },

    unconvertForm(form) {
      this.form = Object.assign({}, form);
      if (this.form.dueDate) {
        this.form.dueDate = this.formatDate(this.form.dueDate);
      }
      if (this.form.receivedDate) {
        this.form.receivedDate = this.formatDate(this.form.receivedDate);
      }
      if (this.form.commissions) {
        this.form.commissions = Object.values(this.form.commissions);
        this.updateAutoCalculate(Object.values(this.form.commissions));
      }
    },

    resetForm() {
      this.errorMessage = null;
      this.form = {
        status: "EXPECTED",
        amountDue: null,
        amountReceived: null,
        dueDate: null,
        receivedDate: null,
        reference: null
      };
      this.paymentStartDate = null;
      this.totalAmountDue = null;
      this.autoCalculate.monthlyAmountDue = true;
      this.paymentType = "ONE-OFF";
    },

    editPayment(payment) {
      this.visible = true;
      this.unconvertForm(payment);
    },

    paidInFull(payment) {
      this.editPayment(payment);
      this.form.status = "RECEIVED";
      this.form.amountReceived = this.form.amountDue;
      this.form.receivedDate = this.form.dueDate;
    },

    fillInFields() {
      if (this.paymentType == "RECURRING") {
        this.paymentStartDate = this.formatDate(this.contract.startDate);
      }
    },

    getDefaultCommissionPercentage(userId) {
      const user = this.users.find(u => u.userId === userId);
      if (!user) return 3; // Default if user not found

      // Check if there are other APMs or CPMs
      const otherCommissions = this.form.commissions.filter(comm => comm.userId !== userId);
      const otherUsers = otherCommissions.map(comm =>
        this.users.find(u => u.userId === comm.userId)
      );
      const hasOtherAPM = otherUsers.some(u => u.apmId);
      const hasOtherCPM = otherUsers.some(u => u.cpmId);

      if (user.apmId) {
        return hasOtherCPM ? 15 : 15; // APM gets 15% regardless of other CPM presence
      } else if (user.cpmId) {
        return hasOtherAPM ? 25 : 35; // CPM gets 25% if APM present, otherwise 35%
      } else if (user.opsId) {
        if (user.email === "sultan.ijaz@reducer.co.uk") {
          return 15; // Sultan as Ops
        }
        return 3; // Default for other Ops
      }

      // Default case
      return 3;
    },

    calculateExpectedCommission(percentage) {
      return (((this.form.amountDue || 0) * (percentage || 0)) / 100).toFixed(2);
    },

    calculateReceivedCommission(percentage) {
      return (((this.form.amountReceived || 0) * (percentage || 0)) / 100).toFixed(2);
    },

    updateCommissionPercentage(c, userId, autoCalculate) {
      if (autoCalculate && userId) {
        this.$set(c, "percentage", this.getDefaultCommissionPercentage(userId));
      }
      if (c._acExpectedCommission) {
        c.expectedCommission = this.calculateExpectedCommission(c.percentage);
      }
      if (c._acReceivedCommission) {
        c.receivedCommission = this.calculateReceivedCommission(c.percentage);
      }
      const user = this.users.find(u => u.userId === userId);
      c.userName = user.label;

      // Update other comm percentages
      for (let index = 0; index < this.form.commissions.length; index++) {
        if (this.form.commissions[index].userId !== userId) {
          this.form.commissions[index].percentage = this.getDefaultCommissionPercentage(
            this.form.commissions[index].userId
          );
        }
      }
    },

    updateAutoCalculate(commissions) {
      commissions.forEach(c => {
        if (c.userId) {
          const defaultCommission = this.getDefaultCommissionPercentage(c.userId);
          c._acPercentage = defaultCommission === c.percentage;
        } else {
          c._acPercentage = true;
        }
        if (c.expectedCommission == this.calculateExpectedCommission(c.percentage)) {
          c._acExpectedCommission = true;
        }
        if (c.receivedCommission == this.calculateReceivedCommission(c.percentage)) {
          c._acReceivedCommission = true;
        }
      });
    },

    toggleAcPercentage(c) {
      this.updateCommissionPercentage(c, c.userId, !c._acPercentage);
      this.$set(c, "_acPercentage", !c._acPercentage);
      this.form.commissions = [...this.form.commissions]; // force update
    },

    toggleAcExpectedCommission(c) {
      if (!c._acExpectedCommission) {
        this.$set(c, "expectedCommission", this.calculateExpectedCommission(c.percentage));
      }
      this.$set(c, "_acExpectedCommission", !c._acExpectedCommission);
      this.form.commissions = [...this.form.commissions];
    },

    toggleAcReceivedCommission(c) {
      if (!c._acReceivedCommission) {
        this.$set(c, "receivedCommission", this.calculateReceivedCommission(c.percentage));
      }
      this.$set(c, "_acReceivedCommission", !c._acReceivedCommission);
      this.form.commissions = [...this.form.commissions];
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    onAmountDueChange() {
      if (this.form.commissions) {
        this.form.commissions.forEach(c => {
          if (c._acExpectedCommission) {
            c.expectedCommission = this.calculateExpectedCommission(c.percentage);
          }
        });
      }
    },

    onAmountReceivedChange() {
      if (this.form.commissions) {
        this.form.commissions.forEach(c => {
          if (c._acReceivedCommission) {
            c.receivedCommission = this.calculateReceivedCommission(c.percentage);
          }
        });
      }
    },

    deleteCommission(id) {
      this.form.commissions = this.form.commissions.filter(c => c.id !== id);
      // Update other comm percentages
      for (let index = 0; index < this.form.commissions.length; index++) {
        this.form.commissions[index].percentage = this.getDefaultCommissionPercentage(
          this.form.commissions[index].userId
        );
      }
    },

    addCommission(i) {
      const newCom = {
        id: Date.now() + (i || 0),
        expectedCommission: null,
        receivedCommission: null,
        _acPercentage: true,
        _acReceivedCommission: true,
        _acExpectedCommission: true
      };
      if (!this.form.commissions) {
        this.$set(this.form, "commissions", [newCom]);
      } else {
        this.form.commissions.unshift(newCom);
      }
      return newCom;
    }
  }
};
</script>

<style lang="scss">
#contract-payment-editor {
  .label-btn {
    padding: 2px 5px;
    position: absolute;
    top: 0;
    right: 1rem;
  }
  .delete-commission-btn {
    position: absolute;
    right: 2px;
    top: 5px;
    cursor: pointer;
    width: 30px;
    text-align: center;
    z-index: 2;
  }
  .add-commission-btn {
    transform: rotate(45deg);
  }
}
</style>
