<template>
  <b-container id="contract-payments">
    <vue-headful title="Payments | Reducer" />
    <ContractEditor ref="contractModal"></ContractEditor>
    <h1 class="mb-3">
      <div class="d-flex justify-content-between align-items-end">
        Contract Payments
        <!-- <b-button v-if="contract" variant="primary" v-b-modal="'contract-payment-editor'"
          >New Payment</b-button
        > -->
      </div>
    </h1>
    <Busy v-if="busy" :size="3" text="Loading..." primary />
    <div v-else-if="contract">
      <!-- Contract Info -->
      <div class="bg-light p-3 d-flex">
        <div class="mr-5 pr-4 contract-info">
          <div class="py-1"><b>Organisation:</b> {{ orgShortName(contract.organisationName) }}</div>
          <div class="py-1">
            <b>Deal:</b> {{ contract.newSupplierName }} ({{ formatCobold(contract.product) }})
            {{
              contract.paymentFromName == contract.newSupplierName
                ? ""
                : "via " + contract.paymentFromName
            }}
          </div>
          <div class="py-1">
            <b>Contract Date:</b>
            {{ formatDate(contract.startDate) + " - " + formatDate(contract.endDate) }}
          </div>
          <!-- <div class="py-1"><b>Contract End:</b> {{ formatDateFull(contract.endDate) }}</div> -->
          <div class="py-1"><b>Contract Length:</b> {{ contract.contractLength }} month</div>
        </div>
        <div v-if="payments" class="bg-white border p-3 d-flex flex-grow-1 rounded">
          <div class="my-auto px-3 flex-grow-1">
            <span class="mb-2">Expected payments</span>
            <h4 v-if="contract">{{ formatPrice(contract.commissionNet) }}</h4>
            <h4 v-else>--</h4>
            <span class="mb-2">Expected commission</span>
            <h4 v-if="commissionExpected">{{ formatPrice(commissionExpected) }}</h4>
            <h4 v-else>--</h4>
            <!-- <span v-if="paymentAmountExpected > paymentAmountReceived" class="small text-muted"
              >{{ formatPrice(paymentAmountExpected - paymentAmountReceived) }} to be paid</span
            > -->
          </div>
          <div class="my-auto px-3 flex-grow-1 dashed-border border-right border-left">
            <span class="mb-2">Received payments</span>
            <h4 v-if="paymentAmountReceived" class="text-success">
              {{ formatPrice(paymentAmountReceived) }}
            </h4>
            <h4 v-else>--</h4>
            <span class="mb-2">Received commission</span>
            <h4 v-if="commissionReceived" class="text-success">
              {{ formatPrice(commissionReceived) }}
            </h4>
            <h4 v-else>--</h4>
            <!-- <span v-if="paymentsReceived.length < payments.length" class="small text-muted"
              >{{ paymentsReceived.length }} out of {{ payments.length }} received</span
            > -->
          </div>
          <div class="my-auto px-3 flex-grow-1">
            <span class="mb-2">Overdue payments</span>
            <h4 v-if="latePaymentAmount" class="text-danger">
              {{ formatPrice(latePaymentAmount) }}
            </h4>
            <h4 v-else>--</h4>
            <span class="mb-2">Overdue commission</span>
            <h4 v-if="commissionOverdue" class="text-danger">
              {{ formatPrice(commissionOverdue) }}
            </h4>
            <h4 v-else>--</h4>
            <!-- <span v-if="paymentsExpected.length" class="small text-muted"
              >Last payment due: {{ formatDate(paymentsExpected[0].dueDate) }}</span
            > -->
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end my-2">
        <b-button variant="primary" size="sm" @click="$refs.contractModal.show(contractId)"
          >Edit Contract</b-button
        >
        <b-button variant="primary" v-b-modal="'contract-payment-editor'" size="sm" class="ml-2"
          >New Payment</b-button
        >
        <b-button
          variant="primary"
          @click="generatePayments"
          size="sm"
          class="ml-2"
          :disabled="busyGenerating"
          style="width: 9rem"
          ><Busy v-if="busyGenerating" :size="1" class="m-0" /><span v-else
            >Generate Payments</span
          ></b-button
        >
      </div>

      <!-- Payments Table -->
      <b-table :fields="paymentFields" :items="payments" empty-text="No payments" show-empty>
        <template v-slot:cell(actions)="row">
          <b-dropdown text="Actions" size="sm">
            <b-dropdown-item @click="$refs.editor.editPayment(row.item)">
              <font-awesome-icon :icon="['far', 'edit']" class="text-muted mr-2" />Edit payment
            </b-dropdown-item>
            <b-dropdown-item
              v-if="row.item.status == 'EXPECTED'"
              @click="$refs.editor.paidInFull(row.item)"
            >
              <font-awesome-icon :icon="['fas', 'check']" class="text-success mr-2" />Paid in full
            </b-dropdown-item>
            <b-dropdown-item @click="showDeletePaymentModal(row.item)">
              <font-awesome-icon :icon="['far', 'trash-alt']" class="text-muted mr-2" />Delete
              payment
            </b-dropdown-item>
            <template v-if="row.item.status == 'EXPECTED'">
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="showDeletePaymentModal()">
                <font-awesome-icon :icon="['far', 'trash-alt']" class="text-muted mr-2" />Delete all
                expected
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>

        <template #cell(late)="row">
          <b-badge v-if="row.value" class="ml-1" variant="danger">DUE</b-badge>
        </template>
        <template #cell(status)="row">
          <b-badge v-if="row.value == 'RECEIVED'" class="ml-1" variant="success">Received</b-badge>
          <b-badge v-else class="ml-1" variant="primary">Expected</b-badge>
        </template>
        <template #cell(dueDate)="row">{{ formatDate(row.value) }}</template>
        <template #cell(amountDue)="row">{{ formatPrice(row.value) }}</template>
        <template #cell(receivedDate)="row">
          {{ formatDate(row.value) }}
        </template>
        <template #cell(amountReceived)="row">{{ formatPrice(row.value) }}</template>
        <template #cell(commissions)="row">
          <div
            class="pointer"
            @click="
              () =>
                $set(
                  showExpectedCommsBreakdown,
                  row.item.paymentId,
                  !showExpectedCommsBreakdown[row.item.paymentId]
                )
            "
          >
            <span v-html="expectedCommsTable[row.item.paymentId]"></span>
            <font-awesome-icon
              v-if="expectedCommsTable[row.item.paymentId].includes('Total')"
              :icon="['fas', 'caret-up']"
              class="ml-1"
            />
            <font-awesome-icon v-else :icon="['fas', 'caret-down']" class="ml-1" />
          </div>
        </template>
        <template #cell(id)="row">
          <div
            class="pointer"
            @click="
              () =>
                $set(
                  showReceivedCommsBreakdown,
                  row.item.paymentId,
                  !showReceivedCommsBreakdown[row.item.paymentId]
                )
            "
          >
            <span v-html="receivedCommsTable[row.item.paymentId]"></span>
            <font-awesome-icon
              v-if="receivedCommsTable[row.item.paymentId].includes('Total')"
              :icon="['fas', 'caret-up']"
              class="ml-1"
            />
            <font-awesome-icon v-else :icon="['fas', 'caret-down']" class="ml-1" />
          </div>
        </template>
      </b-table>
    </div>

    <ContractPaymentEditor
      ref="editor"
      :contractId="contractId"
      :contract="contract"
      :users="internalUsers"
      @paymentSaved="fetchPayments()"
    />
    <b-modal v-model="deletePaymentModal.visible" @ok="deletePayment()">
      <span v-if="deletePaymentModal.paymentIds.length == 1">
        Are you sure you want to delete this payment?
      </span>
      <span v-else> Are you sure you want to delete ALL expected payments for this contract? </span>
    </b-modal>
  </b-container>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import Busy from "@/components/Busy";
import ContractPaymentEditor from "@/components/contracts/ContractPaymentEditor.vue";
import ContractEditor from "@/components/contracts/ContractEditor";

export default {
  name: "ContractPayments",
  components: { Busy, ContractPaymentEditor, ContractEditor },
  data() {
    return {
      contractId: null,
      busy: false,
      busyGenerating: false,

      contract: null,
      payments: [],
      internalUsers: [],
      paymentEdited: null,

      paymentAmountExpected: null,
      paymentAmountReceived: null,
      latePaymentAmount: null,
      latePaymentCommission: null,

      showExpectedCommsBreakdown: {},
      showReceivedCommsBreakdown: {},

      deletePaymentModal: {
        visible: false,
        paymentIds: []
      },

      paymentFields: [
        { key: "actions", label: "" },
        { key: "late", label: "" },
        { key: "status" },
        { key: "dueDate", label: "Expected Date" },
        { key: "amountDue", label: "Expected Payment" },
        { key: "commissions", label: "Expected Comms" },
        { key: "receivedDate", label: "Received Date" },
        { key: "amountReceived", label: "Received Payment" },
        { key: "id", label: "Received Comms" },
        { key: "paymentFromReference", label: "Payment Ref" },
        { key: "reference", label: "Notes" }
      ]
    };
  },
  computed: {
    paymentsExpected() {
      return this.payments.filter(p => p.status == "EXPECTED");
    },
    paymentsReceived() {
      return this.payments.filter(p => p.status == "RECEIVED");
    },
    commissionExpected() {
      let total = 0;
      this.payments.forEach(p => {
        if (p.commissions) {
          Object.values(p.commissions).forEach(c => (total += c.expectedCommission || 0));
        }
      });
      return total;
    },
    commissionReceived() {
      let total = 0;
      this.payments.forEach(p => {
        if (p.commissions) {
          Object.values(p.commissions).forEach(c => (total += c.receivedCommission || 0));
        }
      });
      return total;
    },
    commissionOverdue() {
      let total = 0;
      this.payments.forEach(p => {
        if (p.commissions && p.late) {
          Object.values(p.commissions).forEach(c => (total += c.expectedCommission || 0));
        }
      });
      return total;
    },
    expectedCommsTable() {
      const expectedComms = {};
      this.payments.forEach(
        p =>
          (expectedComms[p.paymentId] = this.showExpectedCommsBreakdown[p.paymentId]
            ? this.getCommsBreakdown(p.commissions, "expectedCommission")
            : this.getTotalExpectedComms(p.commissions))
      );
      return expectedComms;
    },
    receivedCommsTable() {
      const receivedComms = {};
      this.payments.forEach(
        p =>
          (receivedComms[p.paymentId] = this.showReceivedCommsBreakdown[p.paymentId]
            ? this.getCommsBreakdown(p.commissions, "receivedCommission")
            : this.getTotalReceivedComms(p.commissions))
      );
      return receivedComms;
    }
  },
  created() {
    this.contractId = this.$route.params.contractId;
    this.fetchContract();
    this.fetchPayments();
    this.fetchInternalUsers();
  },
  methods: {
    orgShortName: FormatHelper.orgShortName,
    formatPrice: p => (p ? FormatHelper.formatPrice(p) : null),
    formatCobold: FormatHelper.formatCoboldCase,
    formatDate: FormatHelper.formatDate,
    formatDateFull: d => FormatHelper.formatDateTimeCustom(d, "Do MMMM YYYY"),

    async fetchPayments() {
      this.busy = true;
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}payments/${this.contractId}`)
        .then(response => {
          Console.log("Contract payments response :", response);
          this.setPaymentsResponse(response);
        })
        .catch(e => {
          this.showMessage("There was a problem getting contract payments", "warning");
          Console.error(e);
        })
        .finally(() => (this.busy = false));
    },

    async fetchInternalUsers() {
      const client = await ApiHelper.http();
      await client
        .get(`${ApiHelper.endPoint()}users?internalOnly=true`)
        .then(response => {
          console.log("Internal Users:", response);
          this.internalUsers = response.data.users.map(u => ({
            ...u,
            label: `[${
              u.apmId ? "APM" : u.cpmId ? "CPM" : "Ops"
            }] ${FormatHelper.displayNameFromEmail(u.email)}`
          }));
        })
        .catch(e => {
          console.error(e);
          this.showMessage("There was an issue fetching internal user data.", "warning");
        });
    },

    async deletePayment() {
      this.busy = true;
      const client = await ApiHelper.http();
      return client
        .delete(
          `${ApiHelper.endPoint()}payments/${
            this.contractId
          }?paymentId=${this.deletePaymentModal.paymentIds.join("&paymentId=")}`
        )
        .then(response => {
          this.showMessage("Payment(-s) deleted successfully", "success");
        })
        .catch(e => {
          this.showMessage("There was a problem deleting contract payment(-s)", "warning");
          Console.error(e);
        })
        .finally(() => this.fetchPayments());
    },

    async fetchContract() {
      this.busy = true;
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}contracts/${this.contractId}`)
        .then(response => {
          Console.log("Contract response :", response);
          this.contract = response.data.contract;
        })
        .catch(e => {
          this.showMessage("There was a problem getting contract", "warning");
          Console.error(e);
        })
        .finally(() => (this.busy = false));
    },

    async generatePayments() {
      this.busyGenerating = true;
      const client = await ApiHelper.http();
      return client
        .get(`${ApiHelper.endPoint()}payments/${this.contractId}?generated=true`)
        .then(response => {
          this.setPaymentsResponse(response);
        })
        .catch(e => {
          this.showMessage(e.response.data.message, "warning");
          Console.error(e);
        })
        .finally(() => (this.busyGenerating = false));
    },

    showDeletePaymentModal(payment) {
      this.deletePaymentModal.visible = true;
      this.deletePaymentModal.paymentIds = payment
        ? [payment.paymentId]
        : this.payments.filter(p => p.status == "EXPECTED").map(p => p.paymentId);
    },

    setPaymentsResponse(response) {
      this.payments = response.data.payments;
      this.paymentAmountExpected = response.data.paymentAmountExpected;
      this.paymentAmountReceived = response.data.paymentAmountReceived;
      this.latePaymentAmount = response.data.latePaymentAmount;
      this.latePaymentCommission = response.data.latePaymentCommission;
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    getTotalExpectedComms(commissions) {
      let total = 0;
      if (commissions) {
        Object.values(commissions).forEach(c => (total += c.expectedCommission || 0));
        return `£${total}`;
      }
      return "";
    },

    getTotalReceivedComms(commissions) {
      let total = 0;
      if (commissions) {
        Object.values(commissions).forEach(c => (total += c.receivedCommission || 0));
        return `£${total}`;
      }
      return "";
    },

    getCommsBreakdown(commissions, typeOfComms) {
      let total = 0;
      let apm = 0;
      let cpm = 0;
      let ops = 0;

      if (commissions) {
        Object.values(commissions).forEach(c => {
          total += c[typeOfComms] || 0;
          if (c.userName.includes("[CPM]")) {
            cpm += c[typeOfComms] || 0;
          } else if (c.userName.includes("[APM]")) {
            apm += c[typeOfComms] || 0;
          } else if (c.userName.includes("[Ops]")) {
            ops += c[typeOfComms] || 0;
          }
        });
      }

      return `Total: £${total}</br>
      APM: £${apm}</br>
      CPM: £${cpm}</br>
      Ops: £${ops}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";
#contract-payments {
  max-width: 1300px;
  @include badge-styles;
  .contract-info {
    color: $color-font-headings !important;
  }
}
</style>
